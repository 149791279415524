@font-face {
  font-family: "Lidl Font Pro";
  src: url("../assets/fonts/LidlFontPro-Regular.woff2") format("woff2"),
    url("../assets/fonts/LidlFontPro-Regular.woff") format("woff");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lidl Font Pro";
  src: url("../assets/fonts/LidlFontPro-Semibold.woff2") format("woff2"),
    url("../assets/fonts/LidlFontPro-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Lidl Font Pro";
  src: url("../assets/fonts/LidlFontPro-Bold.woff2") format("woff2"),
    url("../assets/fonts/LidlFontPro-Bold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
