html {
    scroll-behavior: smooth;
}

body {
    font-family: "Lidl Font Pro", sans-serif;
    margin: 0;
    -webkit-font-smoothing: auto;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

h1 {
    font-size: 8rem;
    font-family: "Lidl Font Pro";
    font-weight: 800;
}

h2 {
    font-family: "Lidl Font Pro";
    font-size: 3rem;
    font-weight: 800;
}

h3 {
    font-family: "Lidl Font Pro";
    font-size: 3rem;
    font-weight: 600;
}

h4 {
    font-family: "Lidl Font Pro";
    font-size: 2ren;
    font-weight: 800;
}

h5 {
    font-family: "Lidl Font Pro";
    font-size: 24;
    font-weight: 800;
}

p,
label {
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.5rem;
}

section {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
}

footer {
    position: relative;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

#wrapper {
    perspective: 5px;
    perspective-origin: center top;
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.color-white {
    color: #fff;
}

.color-yellow {
    color: #fff000;
}

.color-light-blue {
    color: #68a5e9;
}

.color-dark-blue {
    color: #2777d1;
}

.bg-transparent {
    background-color: transparent;
    background: transparent;
}

.bg-yellow {
    background-color: #fff000;
    background: #fff000;
}

.bg-light-blue {
    background-color: #88b3ec;
    background: #88b3ec;
}

.bg-dark-blue {
    background-color: #2777d1;
    background: #2777d1;
}

.bg-dark {
    background-color: #2777d1;
    background: #2777d1;
}

.bg-white {
    background-color: #fff;
    background: #fff;
}
