header {
    height: 100vh;
    max-width: 100vw;
    z-index: 1;
    margin-top: -20px;
}

iframe {
    width: 100%;
    height: 40vh;
    border-radius: 8px;
    margin-bottom: 50px;
}

#background-video {
    width: 100vw;
    height: 110vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

main {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

img {
    object-fit: cover;
    max-width: 100%;
    height: auto;
}

img.logo {
    position: absolute;
    width: 130px;
    object-fit: cover;
    top: 40px;
    left: 40px;
    margin-top: 0 !important;
}

#lidl-hero-text {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    bottom: 0;
}

video {
    background: #fff000;
    background-color: #fff000;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.outline-button {
    font-size: 1.5rem;
    font-weight: 800;
    display: inline-block;
    min-width: 200px;
    padding: 13px;
    padding-right: 30px;
    padding-left: 30px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    color: #fff000 !important;
    border-width: 2px;
    border-style: solid;
    border-color: #2777d1;
    border-radius: 30px;
    background: #2777d1;
    text-decoration: none;
}

.outline-button:hover {
    transition: all 0.2s ease-in;
    color: #fff000 !important;
    border-color: #fff000;
    background: #2777d1;
}

.outline-button-blue {
    width: 160px;
    color: #fff000 !important;
    border-color: #2777d1;
    background: #2777d1;
}

.outline-button-blue:hover {
    color: #2777d1 !important;
    border-color: #2777d1;
    background: #fff000;
}

@media (max-width: 1200px) {
    img.logo {
        width: 110px;
    }
}

@media (max-width: 992px) {
    img.logo {
        width: 90px;
    }

    h2 {
        font-size: 3rem;
        line-height: 3.1rem;
    }

    h3 {
        font-size: 2rem;
        line-height: 2rem;
    }
}

@media (max-width: 768px) {
    h2 {
        font-size: 2rem;
        line-height: 2.3rem;
    }

    h3 {
        font-size: 1.5rem;
        line-height: 1.6rem;
    }

    .outline-button {
        font-size: 1.2rem;
        margin-bottom: 0;
    }

    section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    img.logo {
        width: 70px;
        position: relative;
        top: 40px;
        left: 40px;
    }
}

@media (max-width: 576px) {
    main {
        min-height: 100vh;
        background-size: auto;
        background-position: center;
        background-attachment: fixed;
    }
    h1 {
        font-size: 3rem;
        text-align: center;
    }
    h2 {
        font-size: 1.8rem;
        line-height: 2rem;
    }

    h3 {
        font-size: 1.2rem;
        line-height: 1.3rem;
    }

    img.logo {
        width: 70px;
        position: relative;
        top: 40px;
        left: 20px;
    }

    #lidl-hero-text {
        width: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 1vh;
    }
}

#cookie-policy-container {
    padding: 2rem;
}

#cookie-policy-header {
    height: 5vh;

    padding: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

#cookie-policy-header > img {
    width: 4rem;
    height: 4rem;
}
